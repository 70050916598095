import React from "react";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-383"
        className="post-383 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Huishoudelijk reglement</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <ol>
            <li>
              Inschrijvingen zijn strikt persoonlijk en niet overdraagbaar.
            </li>
            <li>
              Lesgeld moet betaald worden op de 1ste lesdag met uitzondering van
              beginnende leden (beginnende leden betalen voor aanvang van de 2de
              les)
            </li>
            <li>
              Betaling van lessen gebeurt contant. (wij beschikken niet over een
              betaalterminal)
            </li>
            <li>
              Betaalde danslessen zijn niet overdraagbaar naar een ander
              kalenderjaar of dansseizoen en zijn niet terug betaalbaar.
            </li>
            <li>
              De leden worden opgedeeld volgens gekozen dansdiscipline en mogen
              niet deelnemen aan andere disciplines of veranderen van uur. Dit
              kan enkel uitzonderlijk toegestaan worden in overleg met Dance
              Square.
            </li>
            <li>
              Dance Square behoudt zich het recht op leden volgens ervaring,
              vorderingen en leeftijd te verplaatsen naar een andere
              gelijkaardige les.
            </li>
            <li>
              De leden worden geacht 5 minuten voor aanvang van de les aanwezig
              te zijn zonder storing van de les die bezig is.
            </li>
            <li>
              Bezoekers worden gevraagd om tijdens de lessen zo stil mogelijk te
              zijn.
            </li>
            <li>
              Bij het verlaten van de zalen vragen wij de rust en stilte te
              respecteren voor de lessen die bezig zijn of omwonenden.
            </li>
            <li>
              Eigen meegebrachte dranken en voedsel worden niet toegelaten.
            </li>
            <li>
              Het maken van foto’s of beeldmateriaal wordt niet toegestaan
              tijdens danslessen en oefenavonden tenzij door Dance Square zelf.
            </li>
            <li>
              Dance Square kan niet aansprakelijk gesteld worden voor verloren
              of gestolen voorwerpen. Laat waardevolle voorwerpen thuis.
            </li>
            <li>
              Gevonden voorwerpen worden bewaard door Dance Square en kunnen
              voor of na de les bekeken worden.
            </li>
            <li>
              Dance Square behoudt zich het recht om danslessen te schrappen,
              verplaatsen, te splitsen en/of het aanbod van de danslessen
              digitaal aan te bieden.
            </li>
            <li>
              Dance Square mag ten allen tijden leden het bijwonen van een les
              ontzeggen wegens overlast en storende factor voor andere leden.
            </li>
          </ol>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
